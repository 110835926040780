@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Lobster&display=swap");

* {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

:root {
  --theme-color: #0c092b;
  --violet: #ea7af4;
  --russian-violet: #6a0185;
  --midnight-green-eagle-green: #20288b;
  --tart-orange: #f0544f;
  --white: #f0ebeb;
  --black: #080808;
  --bubble-duration: 0.5s;
}

#symptoms li {
  background-color: transparent;
}

#floating_img_1 {
  position: absolute;
  width: 6rem;
  left: -2rem;
  bottom: 1rem;
  z-index: 1;
}

#floating_img_2 {
  position: absolute;
  width: 6rem;
  right: 0rem;
  top: 3rem;
  z-index: 1;
}

#floating_img_3 {
  position: absolute;
  width: 6rem;
  left: -2rem;
  bottom: 1rem;
  z-index: 1;
}

#floating_img_4 {
  position: absolute;
  width: 6rem;
  right: -2rem;
  top: 0.1rem;
  z-index: 1;
}

#floating_img_5 {
  position: absolute;
  width: 6rem;
  right: 0.5rem;
  top: 5rem;
  z-index: 1;
}

#world_data {
  position: relative;
}

#world_data .row {
  position: relative;
}

#world_data .row::after {
  content: "";
  position: absolute;
  height: 90%;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 2px;
  background-color: #babbba;
}

.about p {
  line-height: 2.5rem;
  font-size: 1.1rem;
  text-align: left;
}

#white_img {
  position: absolute;
  top: 6rem;
  z-index: -1;
  left: -4rem;
  width: 10rem;
  -webkit-animation: rotate 12s linear infinite;
  animation: rotate 12s linear infinite;
}

#live-cases {
  position: relative;
}

#live-cases a:before {
  content: "";
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #d63535;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  -webkit-animation: flash 0.5s infinite alternate ease-in-out;
  animation: flash 0.5s infinite alternate ease-in-out;
}

@-webkit-keyframes flash {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes flash {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#country_list {
  max-height: 300px;
  overflow: auto;
  position: relative;
  transition: height .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.country-active {
  position: absolute;
  opacity: 1;
  transition: opacity .5s cubic-bezier(0.175, 0.885, 0.32, 1.275), transform .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.country-inactive {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: opacity .5s cubic-bezier(0.175, 0.885, 0.32, 1.275), transform .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

#country_wise_cases {
  position: relative;
}

#country_list li {
  color: var(--theme-color);
}

#country_list li:hover {
  background-color: #e1e9e9 !important;
  cursor: pointer;
}

#country_cases div div:nth-child(1) .jumbotron {
  height: 200px;
  background: url(./assets/brushed_alum.png),
    -webkit-gradient(linear, left top, left bottom, from(#e22b59), to(#db5975));
  background: url(./assets/brushed_alum.png),
    -o-linear-gradient(top, #e22b59, #db5975);
  background: url(./assets/brushed_alum.png),
    linear-gradient(to bottom, #e22b59, #db5975);
}

#country_cases div div:nth-child(2) .jumbotron {
  height: 200px;
  background: url(./assets/brushed_alum.png),
    -webkit-gradient(linear, left top, left bottom, from(#447acf), to(#1b4392));
  background: url(./assets/brushed_alum.png),
    -o-linear-gradient(top, #447acf, #1b4392);
  background: url(./assets/brushed_alum.png),
    linear-gradient(to bottom, #447acf, #1b4392);
}

#country_cases div div:nth-child(3) .jumbotron {
  height: 200px;
  background: url(./assets/brushed_alum.png),
    -webkit-gradient(linear, left top, left bottom, from(#5a5858), to(#747272));
  background: url(./assets/brushed_alum.png),
    -o-linear-gradient(top, #5a5858, #747272);
  background: url(./assets/brushed_alum.png),
    linear-gradient(to bottom, #5a5858, #747272);
}

#country_cases div div:nth-child(4) .jumbotron {
  height: 200px;
  background: url(./assets/brushed_alum.png),
    -webkit-gradient(linear, left top, left bottom, from(#7e3caa), to(#b15bd3));
  background: url(./assets/brushed_alum.png),
    -o-linear-gradient(top, #7e3caa, #b15bd3);
  background: url(./assets/brushed_alum.png),
    linear-gradient(to bottom, #7e3caa, #b15bd3);
}

#submit_btn {
  position: relative;
  z-index: 10;
  outline: none;
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  border: 4px solid var(--theme-color);
  display: -ms-grid;
  display: grid;
  place-items: center;
  color: var(--theme-color);
}

#submit_btn span {
  border-radius: 0.4rem;
  border: none;
  position: absolute;
  top: -20%;
  left: -12%;
  width: 120%;
  outline: none;
  height: 140%;
  background-color: transparent;
  z-index: -1;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

#submit_btn:hover {
  color: var(--white);
  -webkit-transition: color 0.3s ease-in;
  -o-transition: color 0.3s ease-in;
  transition: color 0.3s ease-in;
}

#submit_btn span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 70%;
  -webkit-transform: skew(30deg) translate(-50%, -50%);
  -ms-transform: skew(30deg) translate(-50%, -50%);
  transform: skew(30deg) translate(-50%, -50%);
  width: 30%;
  height: 200%;
  background-color: #f8f9fa;
  border: none;
  outline: none;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

#submit_btn:hover span::after {
  background-color: var(--russian-violet);
  outline: none;
  width: 200%;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

#submit_btn:focus {
  outline: none !important;
  outline-offset: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

#world_data .col-md-3:nth-child(-n + 3)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #bbbaba;
  width: 2px;
  height: 50%;
}

button:disabled,
select:disabled {
  cursor: not-allowed;
  pointer-events: auto;
}

.weeks_select {
  width: 10rem;
  max-width: 10rem;
  border-color: #0e5763;
  color: #0e5763;
}

.weeks_select:hover {
  cursor: pointer;
}

.chart-wrapper {
  width: 100%;
  height: 60vh;
}

@media (max-width: 768px) {
  #world_data .col-md-3:nth-child(-n + 3)::after {
    z-index: -1;
  }

  .heading {
    font-size: 2rem;
  }

  .main-jumbotron {
    margin: 0;
    border-radius: 0;
  }

  #needs_help {
    padding: 0;
  }
}

@media (min-width: 768px) {
  #world_data .row::after {
    z-index: -1;
  }
}

@media (max-width: 576px) {
  html {
    font-size: 0.8rem;
  }

  .about p {
    line-height: 2.5rem;
    font-size: 1.2rem;
    text-align: left;
  }

  #floating_img_1 {
    left: 1rem;
    bottom: -2rem;
  }

  #floating_img_2 {
    right: 1rem;
    top: 3rem;
  }

  #floating_img_3 {
    left: 1rem;
    bottom: 1rem;
  }

  #floating_img_4 {
    right: 5px;
    top: 5px;
  }

  #live-cases a:before {
    height: 7px;
    width: 7px;
  }
}

@media (max-width: 350px) {
  html {
    font-size: 0.7rem;
  }

  .about p {
    line-height: 2.5rem;
    font-size: 1.2rem;
    text-align: left;
  }

  #floating_img_1 {
    left: 1rem;
    bottom: -2rem;
  }

  #floating_img_2 {
    right: 1rem;
    top: 3rem;
  }

  #floating_img_4 {
    right: 5px;
    top: 5px;
  }
}

#check_symptoms {
  border-radius: 2rem;
  background-color: var(--russian-violet);
  color: #f3efef;
  outline: none;
  -webkit-box-shadow: 2px 2px 2rem #a223b3;
  box-shadow: 2px 2px 2rem #a223b3;
  -webkit-transition: color 0.2s ease-in, -webkit-transform 0.2s ease-in;
  transition: color 0.2s ease-in, -webkit-transform 0.2s ease-in;
  -o-transition: transform 0.2s ease-in, color 0.2s ease-in;
  transition: transform 0.2s ease-in, color 0.2s ease-in;
  transition: transform 0.2s ease-in, color 0.2s ease-in,
    -webkit-transform 0.2s ease-in;
}

#check_symptoms:hover {
  background-color: #f3efef;
  color: var(--russian-violet);
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: color 0.2s ease-in, -webkit-transform 0.2s ease-in;
  transition: color 0.2s ease-in, -webkit-transform 0.2s ease-in;
  -o-transition: transform 0.2s ease-in, color 0.2s ease-in;
  transition: transform 0.2s ease-in, color 0.2s ease-in;
  transition: transform 0.2s ease-in, color 0.2s ease-in,
    -webkit-transform 0.2s ease-in;
}

.progress {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
  height: 5px;
  border-radius: 0;
}

.progress-bar {
  background-color: var(--russian-violet);
}

.precautions img {
  border-radius: 1rem;
  -webkit-box-shadow: -7px -7px 10px #ffffff, 3px 3px 10px #00000063;
  box-shadow: -7px -7px 10px #ffffff, 3px 3px 10px #00000063;
  -o-object-fit: contain;
  object-fit: contain;
  padding: 0;
}

.precautions {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  padding: 2rem;
  margin: 4rem 0;
  color: var(--russian-violet);
}

.precautions:hover {
  -webkit-box-shadow: 5px -5px 20px #ffffff67, 2px 2px 10px #0000002a;
  box-shadow: 5px -5px 20px #ffffff67, 2px 2px 10px #0000002a;
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.precautions h5 {
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.precautions:hover h5 {
  color: var(--theme-color);
  -webkit-transition: color 0.2s ease-in;
  -o-transition: color 0.2s ease-in;
  transition: color 0.2s ease-in;
}

.content h5,
.content p {
  line-height: 2rem;
}

.content p {
  color: var(--midnight-green-eagle-green);
}

.content {
  margin: 3rem 0;
  padding: 1rem;
}

.heading {
  color: var(--theme-color);
}

#needs_help {
  position: relative;
}

.title {
  color: var(--theme-color);
}

#needs_help::after {
  content: "";
  z-index: -1;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  background-color: var(--theme-color);
}

.footer {
  min-height: 40vh;
  background-color: var(--theme-color);
}

.footer-main-content .sub-heading {
  color: var(--white);
}

.footer-main-content ul a {
  text-decoration: none;
  background: transparent;
  color: var(--white);
  border: 0px;
  -webkit-transition: color 0.2s ease-in;
  -o-transition: color 0.2s ease-in;
  transition: color 0.2s ease-in;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-main-content ul a:hover {
  color: var(--violet);
  -webkit-transition: color 0.2s ease-in;
  -o-transition: color 0.2s ease-in;
  transition: color 0.2s ease-in;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}